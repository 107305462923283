import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Heading from '../../../layout/admin/heading';
import { getApiRequest, apiUrl } from '../../../../utils/request';
import { isBrowser, formatPhoneFromServer, formatDateTime, formatDay } from '../../../../utils/helpers';
import Loadable from '../../../data/loadable';
import AdminTable from '../admin/common/admin-table';
import AutoForm from '../../../form/auto-form';
import { getCenterAdminToken } from '../../../../utils/auth';
import SEO from '../../../layout/seo';
import RightSlideOver from '../../../overlays/right-slide-over';
import RegistrationView from '../bowler/registration-view';


function buildDownloadUrl() {
  const query = queryString.parse(isBrowser ? window.location.search : '');
  query.password = getCenterAdminToken();
  let concatenated = `${apiUrl}/center-admin/registrations/csv?${queryString.stringify(query)}`;
  return concatenated.replace('/api/', '/');
}


function formatRow(item) {
  const {id, user, product, created_at: created, requested_team: teamRequest } = item;
  const {birthday_day: bdayDay, birthday_month: bdayMonth, birthday_year: bdayYear, email, name, phone: phoneRaw } = user;
  const { name: productName, start_date: startDate } = product;
  const bday = bdayDay ? `${bdayMonth}/${bdayDay}/${bdayYear}` : 'NA';
  const phone = phoneRaw ? formatPhoneFromServer(phoneRaw) : '';
  return {
    product: <span>{productName}<br />{formatDay(startDate, 'M/D/Y')}</span>,
    name,
    email,
    phone,
    bday,
    teamRequest,
    created: formatDateTime(created, 'll'),
    id,
    registration: item,
  };
}

const CenterAdminRegistrations = () => {
  const [results, setResults] = useState([]);
  const [sideOpen, setSideOpen] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/center-admin/registrations',
        label: 'Registrations',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadRegistrations = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/center-admin/registrations${queryParamsString ? `?${queryParamsString}&password=${getCenterAdminToken()}` : `?password=${getCenterAdminToken()}`}`);
    // setRegistrations(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadRegistrations();
    }
  }, [queryParamsString]);

  const openSide = () => setSideOpen(true);
  const closeSide = () => {
    setSideOpen(false);
    setRegistration(null);
  };

  const openRegistrationView = (toView) => {
    openSide();
    setRegistration(toView);
  };


  const columns = [
    {
      key: 'product',
      label: 'League',
      // isBold: true,
    },
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'phone',
      label: 'Phone',
    },
    {
      key: 'bday',
      label: 'Birthday',
    },
    {
      key: 'teamRequest',
      label: 'Team',
    },
    {
      key: 'created',
      label: 'Date',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <button
          onClick={() => openRegistrationView(row.registration)}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          View
        </button>
      ),
    },
  ];


  const baseUrl = '/app/center-admin/registrations';

  const optionsSuffix = `?password=${getCenterAdminToken()}`;

  const downloadCsv = () => {
    try {
      const downloadUrl = buildDownloadUrl();
      window.open(downloadUrl, '_blank');
    } catch (err) {
      console.log(err, 'error trying to download...');
    }
  }

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/center-admin/registrations',
    fields: [
      // {
      //   name: 'name',
      //   label: 'Name',
      //   type: 'text',
      // },
      // {
      //   name: 'email',
      //   label: 'Email',
      //   type: 'email',
      // },
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: `/options/leagues${optionsSuffix}`,
      },
      {
        name: 'product_id',
        label: 'Product',
        type: 'select',
        optionsUrl: `/options/products${optionsSuffix}`,
      },
    ],
    submitLabel: 'Search Registrations',
  };

  const links = [
    {
      secondary: true,
      label: 'Download',
      onClick: downloadCsv,
    },
  ];

  return (
    <AdminContainer centerAdmin>
      <SEO title="Registrations" />
      <Heading title="Registrations" links={links} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Registrations" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
      <RightSlideOver darkBg large isOpen={sideOpen} close={closeSide} title="Registration details">
        {sideOpen && !!registration && <RegistrationView registration={registration} user={registration.user} centerAdmin />}
      </RightSlideOver>
    </AdminContainer>
  );
}

export default CenterAdminRegistrations;
